<template>
  <div class="main">
    <el-divider></el-divider>
    <div class="content">
      <div class="xxinput">
        <div class="content-box">
          <el-form :rules="rules" ref="basicInfoRef" :model="formInfo" label-width="210px">
            <div class="content_residentinformation">
              <span class="blue"></span>
              <p>隔离时间</p>
            </div>

            <el-form-item class="formitem" label="开始隔离时间：" prop="startTime">
              <el-date-picker style="width:90%" type="datetime" v-model="formInfo.startTime"
                format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss" placeholder="选择时间"></el-date-picker>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { listCounty } from "../../api/QuarantineRegistration/quarantineRegistrationpop";
import { http, serverIP } from "../../api/index";
import constants from '../../common/constants';
let cities = localStorage.getItem("dqlist");

export default {
  data() {
    return {
      formInfo: {
        livingAreaCode: [],
        destinationCode: [],
        startTime: "",
        cityEntryCode: [],
        lifetime: "", //时间
        arrivalTime: "", //抵重庆时间
        contactTime: "",
        caseName: "",
        destinationDetail: "",
        destinationStreet: "",
        residentFlag: "",
        coughDetail: "",
        vaccinationFinishFlag: ""
      }, //人员信息
      rules: {
        emotionFlag: [{ required: true, message: "请选择情绪是否稳定", trigger: "change" }],
        healthGreenCodeFlag: [{ required: true, message: "请选择是否持健康绿码", trigger: "change" }],
        covid24hProveFlag: [{ required: true, message: "请选择是否持24小时核酸阴性证明", trigger: "change" }],
        temperatureEntry: [{ required: true, message: "请输入体温℃", trigger: "blur" }],
        coughFlag: [{ required: true, message: "请选择有无咳嗽、乏力等症状", trigger: "change" }],
        vaccinationFlag: [{ required: true, message: "请选择是否接种疫苗", trigger: "change" }],
        startTime: [{ required: true, message: "请选择开始隔离时间", trigger: "blur" }],

      },
      carselect: constants.VEHICLE_TYPE,  // 交通工具
      dangerselect: constants.DANGER_TYPE, //危险等级
      hjdselect: [], //户籍地
      nationalityselet: [], // 国籍
      destinationselet: [], // 目的地
      jqdqselect: [], //旅居地区
      props: {
        lazy: true,
        lazyLoad(node, resolve) {
          if (node.level == 0) {
            if ("string" == typeof cities) {
              cities = JSON.parse(cities);
            }
            const nodes = cities.map(item => ({
              value: item.value,
              label: item.label
            }));

            resolve(nodes);
          }
          if (node.level != 0) {
            let formm = { label: node.data.value };
            http({
              method: "post",
              url: `/ohealth/api/v1/system/common/subList`,
              data: formm
            }).then(function (response) {
              let nodes02 = response.data.data;
              const nodesee = nodes02.map(item => ({
                value: item.value,
                label: item.label,
                leaf: item.leaf == 3
              }));

              resolve(nodesee);
            })
          }
        }
      },
      isljdz: false,
      isljdz03: false,
      isljdz02: false,
      iscityEntry: false
    };
  },

  props: {
    // 人群分类
    crowdType: {
      type: [String, Number],
      default: ""
    }
  },

  watch: {
    formInfo: {
      handler(val) {
        if (val.arrivalTime) {
          this.reachDate()
        }
      },
      deep: true
    }
  },

  created() {
    this.listCounty();
    let { rowdata } = this.$route.query;
    if (rowdata === "add") {
      this.isljdz = false;
      this.isljdz03 = false;
      this.isljdz02 = false;
      this.iscityEntry = false;
    } else {
      this.isljdz = true;
      this.isljdz03 = true;
      this.isljdz02 = true;
      this.iscityEntry = true;
    }
  },

  methods: {
    // 提交
    comm() {
      this.$refs["basicInfoRef"].validate(valid => {
        if (valid) {

        } else {
          return false;
        }
      });
    },

    // 重置
    resetf() {
      this.$refs.basicInfoRef.resetFields()
    },

    // 国籍
    async listCounty() {
      let { data } = await listCounty();
      this.nationalityselet = data.data;
    },

    // 入境城市
    handleCityEntry(value) {
      let node = this.$refs.cityEntryRef.getCheckedNodes()[0];
      if (node) {
        let cityEntry = node.pathLabels.toString();
        this.formInfo.cityEntry = cityEntry;
        this.formInfo.cityEntryCode = value;
      }
    },

    // 户籍地
    changeHouseholdPlace(value) {
      let node = this.$refs.householdPlaceRef.getCheckedNodes()[0];
      if (node) {
        let householdPlace = node.pathLabels.toString();
        this.formInfo.householdPlace = householdPlace;
        this.formInfo.householdPlaceCode = value;
      }
    },

    // 	目的地
    handleDestination(value) {
      let node = this.$refs.destinationRef.getCheckedNodes()[0];
      if (node) {
        let destination = node.pathLabels.toString();
        this.formInfo.destination = destination;
        this.formInfo.destinationCode = value;
      }
    },

    optionClick(v) {
      this.formInfo.livingCountryCode = v;
    },

    // 抵渝时间
    reachDate() {
      this.$emit("changReachDate", this.formInfo.arrivalTime)
    }
  }
};
</script>

<style lang='scss' scoped>
.main {
  width: 100%;
  height: 100%;

  .content {
    .content_residentinformation {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      margin-top: 10px;
    }

    .content-box {
      margin-top: 40px;
      width: 100%;

      .content-item {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
  }
}

.blue {
  width: 4px;
  height: 20px;
  background-color: #3476f5;
  margin-right: 6px;
}

.formitem {
  width: 50%;
}

.xxinput {
  margin-bottom: 20px;
}

.bottom {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}
</style>
